import React from 'react'
import Footer from '../components/Footer'
import Story from '../components/Story'
import Hero from '../components/Hero'
import BookNowCTA from '../components/BookNowCTA'



function Home() {
  return (
    <>

  
<Hero />
<BookNowCTA />
<Story />
    <Footer />

    </>
  )
}

export default Home