import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'

function NotFound() {
  return (
<>
<div className='h-screen bg-black text-white flex text-center'>
    <div className='m-auto'>
    <Link to='/'>
    <h2 className='text-2xl'>PAGE NOT FOUND</h2>
    <p>This page isn't found, click here to go home</p>
    </Link>

    </div>
</div>

<Footer />
</>
  )
}

export default NotFound