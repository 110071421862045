import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import useWindowDimensions from '../../tools/WindowDimensions';




// const containerStyle = {
//   width: width,
//   height: '550px'
// };

const center = {
    lat: 51.48633402604975, 
    lng: 0.03256592761062801,
};

function MyComponent() {
  const {width, height} = useWindowDimensions()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCJ55GNDqPBMpGZ0qUt21nxOyygmkcIRmA"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={{width:width * 0.8, height:height / 2}}
        center={center}
        zoom={19}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
  ) : <></>
}

export default React.memo(MyComponent)