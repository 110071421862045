import React from 'react'
import Footer from '../components/Footer'

function Products() {
  return (
    <>
    <div className='bg-black h-screen text-white flex'>
<div className='m-auto'>
        <h2 className='text-4xl'>PRODUCTS COMING SOON</h2>
</div>

    </div>
    <Footer />
    </>
  )
}

export default Products