import React from 'react'
import Button from './Button'
import OpenHours from './OpenHours'
import * as AiIcons from 'react-icons/ai'
import * as FaIcons from 'react-icons/fa'
import KeyDetails from './KeyDetails'


function Footer() {
  return (
    <div className='bg-dbfdarkgrey p-4 text-white'>
        <div className='grid tablet:grid-cols-3'>
            <div className='pt-8'>
<h2 className='text-center uppercase text-2xl'>BOOK</h2>
<div className='flex justify-center'>
<Button text='Book now on Fresha' type='external' link={KeyDetails.bookingLink}/>
</div>
            </div>
            <div className='pt-8'>
<h2 className='text-center uppercase text-2xl'>Open Hours</h2>
<div className='w-full flex justify-center pt-4'>

<div className='w-full'>

{OpenHours.map(day => (
    <div key={day.day} className='flex justify-evenly'>
<p className='px-4'>{day.day}</p>
<p className='px-4'>
    {day.hours}
</p>
        </div>
))}
</div>
</div>
            </div>
            <div className='pt-8'>
<h2 className='text-center uppercase text-2xl'>Socials</h2>
<div className='flex justify-center pt-8'>

<a href='http://instagram.com/dbfcutz' target='_blank'>
    <AiIcons.AiFillInstagram size={60} className='mx-4 hover:text-red-500 hover:scale-125 duration-300'/>
    </a>
<a href='https://www.facebook.com/dbf.cutz' target='_blank'>
    <FaIcons.FaFacebook size={55} className='mx-4 hover:text-red-500 hover:scale-125 duration-300'/>
    </a>
<a href='https://twitter.com/dbfcutz' target='_blank'>
    <FaIcons.FaTwitter size={55} className='mx-4 hover:text-red-500 hover:scale-125 duration-300'/>
    </a>
<a href='https://vm.tiktok.com/ZMN5m724U/' target='_blank'>
    <FaIcons.FaTiktok size={55} className='mx-4 hover:text-red-500 hover:scale-125 duration-300'/>
    </a>
</div>
            </div>
        </div>
<div className='pt-16 text-center tracking-widest'>
        <p>© {new Date().getFullYear()} | DBF CUTZ LTD.</p>
        <p>Site by <a href='https://www.thegeekfloor.com' target='_blank' className='hover:text-tgfgreen'>TheGeekFloor</a></p>
</div>
    </div>
  )
}

export default Footer