import React from 'react'
import Footer from '../components/Footer'
import polaroid from '../assets/images/Hero/resized/hero1.jpg'
import community from '../assets/images/Hero/resized/hero9.jpg'
import kids from '../assets/images/Hero/resized/hero13.jpg'

function About() {
  return (
    <>
    <div className='bg-black text-white pt-32 p-8'>

<div className='flex justify-between items-center'>



<div className='w-1/2'>

    <h2 className='text-2xl p-4 tablet:text-5xl'>OUR STORY</h2>
    <div className='pt-4 p-4'>
    <p>The dynamic duo Obi ‘O1’ and Uche ‘U1’ formally known as Dabeatfreakz were born and raised in Charlton, South East London. Dabeatfreakz are internationally multi-platinum producers, whom have worked with the likes of D block Europe, Krept and Konan, Nines, Cadet, Usher, Sean Paul, Giggs, Deno, Swarmz just to name a few.</p>
</div>


      </div>

      <div className='w-1/2 flex justify-center'>
  <img src={polaroid} alt='polaroid image of owners' />
</div>

</div>
    </div>
    <div className='bg-white text-black p-8'>

    <div className='flex justify-between items-center'>


    <div className='w-1/2 flex justify-center'>
  <img src={community} alt='image of community day'/>
</div>


    {/* <h2 className='text-2xl tablet:text-5xl'>OUR STORY</h2> */}
    <div className='p-4 text-right w-1/2'>
    <p>However, it wasn’t always this way, coming from humble beginnings they feel it is now more important then ever to support and provide for their community. The challenging context of growing up in Charlton was that there were no black owned barbershops, which meant that in order to obtain a stylish haircut the pair were forced to travel to neighbouring areas.</p>

        </div>



</div>
    </div>
    <div className='bg-black text-white p-8'>

    <div className='flex justify-between items-center'>

    {/* <h2 className='text-2xl tablet:text-5xl'>OUR STORY</h2> */}
    <div className='p-4 w-1/2'>
    <p>Despite the risks, they would often have to travel with a group of friends as there was a constant rise in crime occurring in London. This was the catalyst that lead the brothers to opening DBF CUTZ . The first black owned barbershop within their community, a place where all ethnicities, genders and ages can feel welcome and safe.</p>
    </div>

    <div className='w-1/2 flex justify-center'>
  <img src={kids} alt='image of children on community day'/>
</div>
   


</div>
    </div>
    <Footer />
    </>
  )
}

export default About