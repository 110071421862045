import { collection, getDocs, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Sentry } from 'react-activity'
import Footer from '../components/Footer'
import { db } from '../firebase-config'
import "react-activity/dist/library.css";
import Button from '../components/Button'
import KeyDetails from '../components/KeyDetails'


function Prices() {

  const [haircuts, setHaircuts] = useState(null)
  const [extras, setExtras] = useState(null)
  const [premium, setPremium] = useState(null)
  const [mensBraids, setMensBraids] = useState(null)
  const [womensBraids, setWomensBraids] = useState(null)
  const [waxing, setWaxing] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getPrices()
  }, [])

  const getPrices = async () => {
    const q = query(collection(db, 'prices'))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(doc => {
      setHaircuts(doc.data().haircuts)
      setExtras(doc.data().extras)
      setPremium(doc.data().premium)
      setMensBraids(doc.data().mensBraids)
      setWomensBraids(doc.data().womensBraids)
      setWaxing(doc.data().waxing)
     })
    console.log('got prices')
    setLoading(false)
}


  return (
    <>

    <div className='bg-black h-36'>

    </div>
    
    <div className='p-16'>
      <h2 className='text-center text-6xl uppercase pb-8'>Prices</h2>
      {loading ? 

<div className='flex'>

<div className='flex justify-center p-32 m-auto items-center'>
    <Sentry size={30}/>

</div>
</div>
: 
<>
      <div className='grid tablet:grid-cols-3'>

      <div className='text-center border rounded-xl pb-4 m-4'>
        <h2 className='text-center text-3xl pt-8 uppercase pb-4'>Haircuts</h2>
        <p>Mens - {haircuts.mens}</p>
        <p>Womens - {haircuts.womens}</p>
        <p>Teens - {haircuts.teens}</p>
        <p>Children - {haircuts.children}</p>
        <p>Over 65's - {haircuts.over65s}</p>


      </div>

      <div className='text-center border rounded-xl pb-4 m-4'>
        <h2 className='text-center text-3xl pt-8 uppercase pb-4'>Extras</h2>
        <p>Shape up - {extras.shapeUp}</p>
        <p>Shape up with beard - {extras.shapeUpWithBeard}</p>
        <p>Patterns (Adults) - {extras.patternsAdult}</p>
        <p>Patterns (Children) - {extras.patternsChildren}</p>
        <p>Eyebrows shape and slit - {extras.eyebrowShapeAndSlit}</p>
        <p>Eyebrow threading - {extras.eyebrowThreading}</p>
        <p>Hot towel - {extras.hotTowel}</p>
        <p>10 Minute face steam - {extras.tenMinuteFaceSteam}</p>
        <p>Beard Dye - {extras.beardDye}</p>
        <p>Tooth Whitening (40 mins) - {extras.tooth40}</p>
        <p>Tooth Whitening (60 mins) - {extras.tooth60}</p>

        {/* <p>{prices[0].mens}</p> */}
      
      </div>

      <div className='text-center border rounded-xl pb-4 m-4'>
        <h2 className='text-center text-3xl pt-8 uppercase pb-4'>Premium</h2>
        <div className='pb-4'>
          <p>Early Riser / After Hours * - {premium.earlyRiserAfterHours}</p>
        <p className='italic'>* Standard Trim with face mask & Black spray. Only bookable between 8 a.m. and 11 a.m. or 9 p.m. - 11 p.m.</p>
          </div>
        <div className='pb-4'>
          <p>The DBF Special - {premium.theDBFSpecial}</p>
        <p className='italic'>Standard trim with face mask, Face steamer and black spray.</p>
          </div>
        <div className='pb-4'>
          <p>Superstar - {premium.superstar}</p>
        <p className='italic'>Standard trim with face mask, Face steamer and black spray and Hair wash.</p>
          </div>
        <div className='pb-4'>
          <p>International - {premium.international}</p>
        <p className='italic'>Standard trim with face mask, Face steamer, black spray, Hair wash and a mini manicure!</p>
          </div>

      </div>

      <div className='text-center border rounded-xl pb-4 m-4'>
        <h2 className='text-center text-3xl pt-8 uppercase pb-4'>Mens Braids</h2>
        <p>Fulani - {mensBraids.fulani}</p>
        <p>Single Plaits - {mensBraids.singlePlaits}</p>
        <p>Single Twists - {mensBraids.singleTwists}</p>
        <p>Retwists - {mensBraids.retwists}</p>
        <p>Gel Twists - {mensBraids.gelTwists}</p>
        <p>Interlocking - {mensBraids.interlocking}</p>
        <p>Loc Removal - {mensBraids.locRemoval}</p>
        <p>Wash and Condition - {mensBraids.washAndCondition}</p>
        <p>Hot Oil Treatment - {mensBraids.hotOilTreatment}</p>
        <p>2 Cornrows - {mensBraids.twoCornrows}</p>
        <p>4 Cornrows - {mensBraids.fourCornrows}</p>
        <p>6 Cornrows - {mensBraids.sixCornrows}</p>
        <p>8 Cornrows - {mensBraids.eightCornrows}</p>
     

      </div>


      <div className='text-center border rounded-xl pb-4 m-4'>
        <h2 className='text-center text-3xl pt-8 uppercase pb-4'>Womens Braids</h2>
        <p>Butterfly Locs - {womensBraids.butterflyLocs}</p>
        <p>Soft Locs - {womensBraids.softLocs}</p>
        <p>Fulani - {womensBraids.fulani}</p>
        <p>Free Style Fulani - {womensBraids.freeStyleFulani}</p>
        <p>Passion Twists - {womensBraids.passionTwists}</p>
        <p>Sleek Styles - {womensBraids.sleekStyles}</p>
        <p>Sew-Ins - {womensBraids.sewIns}</p>
        <p>Half Braid / Half Sew-In - {womensBraids.halfBraidHalfSewIns}</p>
        <p>Half Ponytail / Half Sew-In - {womensBraids.halfPonytailHalfSewIns}</p>
        <p>2 Braids - {womensBraids.twoBraids}</p>
        <p>4 Braids - {womensBraids.fourBraids}</p>
        <p>6 Braids - {womensBraids.sixBraids}</p>
        <p>8 Braids - {womensBraids.eightBraids}</p>
        <p>10 Cornrows - {womensBraids.tenCornrows}</p>
        <p>12 Cornrows - {womensBraids.twelveCornrows}</p>
        <p>14 Cornrows - {womensBraids.fourteenCornrows}</p>
        <p>20+ Cornrows - {womensBraids.twentyPlusCornrows}</p>
        <p>Goddess Braids - {womensBraids.goddessBraids}</p>
        <p>Cornrows into Ponytail - {womensBraids.cornrowsIntoPonytail}</p>
     



      
      </div>
      
      
      <div className='text-center border rounded-xl pb-4 m-4'>
        <h2 className='text-center text-3xl pt-8 uppercase pb-4'>Waxing</h2>
        <p>Eyebrow Grooming - {waxing.eyebrowGrooming}</p>
        <p>Back and Shoulders - {waxing.backAndShoulders}</p>
        <p>Chest and Back - {waxing.chestAndBack}</p>
        <p>Upper Cheek - {waxing.upperCheek}</p>
        <p>Chest - {waxing.chest}</p>
        <p>Back - {waxing.back}</p>
        <p>Nose - {waxing.nose}</p>
       
     
      </div>


      {/* <div className=' bg-black flex text-center border rounded-xl pb-4 m-4'>
   <div className='m-auto pt-4'>
    <Button text='BOOK NOW' link={KeyDetails.bookingLink} />
   </div>
      
      </div> */}

      </div>
      </>
}
    </div>
    <Footer />
    </>
    )
}

export default Prices