import React, { useState } from 'react'
import one from '../assets/images/Haircuts/one.jpeg'
import two from '../assets/images/Haircuts/two.jpeg'
import three from '../assets/images/Haircuts/three.jpeg'
import four from '../assets/images/Haircuts/four.jpeg'
import five from '../assets/images/Haircuts/five.jpeg'
import six from '../assets/images/Haircuts/six.jpeg'
import seven from '../assets/images/Haircuts/seven.jpeg'
import bYoung from '../assets/images/bYoungSq.jpg'
import Modal from 'react-modal'
import * as AiIcons from 'react-icons/ai'
import * as BsIcons from 'react-icons/bs'
import useWindowDimensions from '../tools/WindowDimensions'
import Footer from '../components/Footer'

function Haircuts() {
  const {height, width} = useWindowDimensions()
  const [modal, setModal] = useState(false)
  const [image, setImage] = useState(0)
const images = [
  {img: one,num: 0},{img: two,num: 1},{img: three,num: 2},{img: four,num: 3},{img: five,num: 4},{img: six,num: 5},{img: seven,num: 6},{img: bYoung,num: 7},
]

const imageUp = () => {
  image === images.length - 1 ? setImage(0) : setImage(image + 1)
}


  return (
    <>
<div className='bg-black h-36'>

</div>

<div className='p-8'>
  <h2 className='text-center text-4xl'>HAIRCUTS, STYLES & BRAIDS</h2>
</div>

<div className='grid grid-cols-3 tablet:grid-cols-5 p-4'>
 {/* <img src={one} className='m-2 p-4 hover:scale-110 duration-300' onClick={() => setModal(true)} onMouseEnter={() => setImage(one)}/>
 <img src={two} className='m-2 p-4 hover:scale-110 duration-300' onClick={() => setModal(true)} onMouseEnter={() => setImage(two)}/>
 <img src={three} className='m-2 p-4 hover:scale-110 duration-300' onClick={() => setModal(true)} onMouseEnter={() => setImage(three)}/>
 <img src={four} className='m-2 p-4 hover:scale-110 duration-300' onClick={() => setModal(true)} onMouseEnter={() => setImage(four)}/>
 <img src={five} className='m-2 p-4 hover:scale-110 duration-300' onClick={() => setModal(true)} onMouseEnter={() => setImage(five)}/>
 <img src={six} className='m-2 p-4 hover:scale-110 duration-300' onClick={() => setModal(true)} onMouseEnter={() => setImage(six)}/>
 <img src={seven} className='m-2 p-4 hover:scale-110 duration-300' onClick={() => setModal(true)} onMouseEnter={() => setImage(seven)}/> */}
 {images.map(img => (
  <img src={img.img} className='m-2 p-2 tablet:p-4 hover:scale-110 duration-300' onClick={() => setModal(true)} onMouseEnter={() => setImage(Math.floor(img.num))}/>
 ))}

</div>

<Modal 
isOpen={modal}
onRequestClose={() => setModal(false)}
className='w-screen h-screen bg-black/60 p-2 tablet:p-8'>
  <div className='absolute w-screen pt-32 tablet:pt-28 pr-8 tablet:pr-44 flex justify-end hover:text-red-600 text-white' onClick={() => setModal(false)}>
<AiIcons.AiOutlineCloseCircle size={40}/>
</div>
  <div className='flex justify-between items-center' style={height > width ? {paddingTop:height /4} : {paddingTop: 150}}>
    <BsIcons.BsChevronLeft size={40} className='text-white hover:text-red-600' onClick={() => imageUp()}/>
    <div className='w-3/4 tablet:w-1/2'>
    <img src={images[image].img} />
    </div>
    <BsIcons.BsChevronRight size={40} className='text-white hover:text-red-600' onClick={() => imageUp()}/>
    </div>
</Modal>

<Footer />
    </>
  )
}

export default Haircuts