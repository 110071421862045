import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import useWindowDimensions from '../tools/WindowDimensions'
import NavData from './NavData'
import * as AiIcons from 'react-icons/ai'
import * as FaIcons from 'react-icons/fa'
import Button from './Button'
import Modal from 'react-modal';
import { useWindowScrollPositions } from '../tools/ScrollPosition'
import KeyDetails from './KeyDetails'

function NavBar() {
    const {width} = useWindowDimensions()
    const [nav, setNav] = useState(false)
    const [fade, setFade] = useState(false)
    const [top, setTop] = useState(true)
    const {scrollY}  = useWindowScrollPositions()

useEffect(() => {
    if (nav) {
        setTimeout(() => {
            setFade(true)
    }, [200])
    } else if (!nav) {
        setFade(false)
    }
})

useEffect(() => {
    if (scrollY > 10) {
        setTop(false)
    } else if (scrollY <11) {
        setTop(true)
    }
})

  return (
<>

{/* {top ? 
<div className='fixed top-0 w-screen p-16 z-10'>
<div className='flex justify-end text-white'>
    <AiIcons.AiOutlineMenu size={40} onClick={() => setNav(true)}/>
</div>
</div>
: null } */}

<div className='absolute top-0 bg-black h-36'>

</div>



  {nav ? 

   <div className='h-screen bg-black/90 fixed p-4 tablet:p-16 w-screen z-10'>
       <div className={fade ? 'opacity-1 transition-opacity duration-300 m-0 h-full border-0' : 'opacity-0'}>
        <div className='flex justify-end hover:text-red-600 text-white' onClick={() => setNav(false)}>

        <AiIcons.AiOutlineCloseCircle size={40}/>
</div>
        <div className='m-auto text-2xl tablet:text-4xl text-center py-8 tablet:py-32 pb-16'>
        {NavData.map(nav => (
            <Link to={nav.path} key={nav.title}>
       <div className='text-white uppercase p-4' onClick={() => setNav(false)}>
        <h2 className='hover:text-red-600 hover:scale-125 duration-300'>{nav.title}</h2>
        </div>
        </Link>
   ))}

        </div>


<div className='flex justify-center pt-8 text-white'>

<a href='http://instagram.com/dbfcutz' target='_blank' onClick={() => setNav(false)}>
    <AiIcons.AiFillInstagram size={40} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
    </a>
<a href='https://www.facebook.com/dbf.cutz' target='_blank' onClick={() => setNav(false)}>
    <FaIcons.FaFacebook size={35} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
    </a>
<a href='https://twitter.com/dbfcutz' target='_blank' onClick={() => setNav(false)}>
    <FaIcons.FaTwitter size={35} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
    </a>
<a href='https://vm.tiktok.com/ZMN5m724U/' target='_blank' onClick={() => setNav(false)}>
    <FaIcons.FaTiktok size={35} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
    </a>
</div>
      
        </div>
   </div>

:


    <div className={top ? 'h-0 opacity-1 transition-opacity duration-500 text-white z-10' : 'opacity-1 transition-opacity duration-500 fixed top-0 bg-black/80 w-screen text-white z-10'}>

<div>
   <div className='flex justify-between px-4 tablet:px-16 py-4 items-center'>
  
    {width > 500 ? 
   <div className='w-1/4 flex justify-start'>
  <Button text='BOOK NOW' type='external' link={KeyDetails.bookingLink}/>
    </div>
:null }


    <div className='w-1/2 flex justify-center'>
<Link to='/'>
        <img src={logo} className='tablet:h-24 w-auto p-4 ml-2' alt='DBF logo'/>
</Link>
    </div>

<div className='w-1/4 flex justify-end'>
    <AiIcons.AiOutlineMenu size={40} onClick={() => setNav(true)}/>
</div>



   </div>

   </div>


   </div>
}
   </>
  )
}

export default NavBar