import React from 'react'
import { Link } from 'react-router-dom'

function Button(props) {
    const cName = 'border border-white text-white px-4 py-2 m-2 uppercase hover:bg-white hover:text-black text-center w-fit'
    const cNameLight = 'border border-black px-4 py-2 m-2 uppercase hover:bg-black hover:text-white text-center w-fit'
    const theme = props.theme
  return (
    <div className=''>
        {props.type === 'internal' ? 
    

    <Link to={props.link}>
        <div className={theme === 'light' ? cNameLight : cName}>
    <button><h2 className='uppercase'>
        {props.text}
        </h2>
        </button>
        </div>
    </Link>

    :

    <div className={theme === 'light' ? cNameLight : cName}>
    <a href={props.link} target='_blank'>
    <button>
        <h2 className='uppercase'>
        {props.text}
        </h2>
        </button>
    </a>
    </div>


}
    </div>
  )
}

export default Button