import React from 'react'

const NavData = [
    {
        title:'Home',
        path:'/'
    },

    // {
    //     title:'Book Now',
    //     path: KeyDetails.bookingLink
    // },

    // {
    //     title:'About',
    //     path:'/about'
    // },
    {
        title:'Prices',
        path:'/prices'
    },
    {
        title:'Haircuts, Styles & Braids',
        path:'/haircuts'
    },
    {
        title:'Products',
        path:'/products'
    },
    // {
    //     title:'Meet the team',
    //     path:'/team'
    // },
    {
        title:'Contact Us',
        path:'/contact'
    },
]

export default NavData