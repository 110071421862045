import React from 'react'
import Footer from '../components/Footer'
import * as AiIcons from 'react-icons/ai'
import KeyDetails from '../components/KeyDetails'
import EmailForm from '../components/EmailForm'
import Map from '../components/map/Map'

function Contact() {



  return (
<>


<div className='bg-black h-36'>
</div>
<div className='pt-16'>
<h2 className='text-4xl text-center'>CONTACT US</h2>
</div>

<div className='tablet:p-8 grid tablet:grid-cols-3 text-center'>
<div className='p-4 m-2 border rounded-xl'>
  <h2 className='text-2xl uppercase pb-2'>Email us</h2>
 <EmailForm />
</div>
<div className='p-4 m-2 border rounded-xl flex justify-center'>
<Map zoom={17}/>
</div>
<div className='p-4 m-2 border rounded-xl'>

<div>
<a href='http://maps.apple.com/?address=43+Charlton+Church+Ln,+Charlton,+London+SE7+7AE&t=m' target='_blank'>
<h2 className='text-2xl uppercase pb-2'>DBF CUTZ</h2>
<p>43 Charlton Church Lane</p>
<p>Charlton</p>
<p>London</p>
<p>SE7 7AE</p>
<p className='text-red-600'>click here for directions</p>
</a>
</div>

<div className='pt-4'>
  <a href='tel:07565 252144'>
  <h2 className='uppercase'>Phone:</h2>
  <p>07565 252 144</p>
  </a>
</div>

<div className='pt-4'>
  <a href='mailto:dbfcutz@gmail.com'>
  <h2 className='uppercase'>Email:</h2>
  <p>dbfcutz@gmail.com</p>
  </a>
</div>

<div className='flex justify-center pt-16 pb-16'>
<div>
  <a href={KeyDetails.bookingLink} target='_blank'>
        <AiIcons.AiFillCalendar size={50} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
        <h3 className='text-center text-3xl uppercase'>Book</h3>
  </a>
    </div>
<div>
<a href='tel:07565 252144' target='_blank'>
        <AiIcons.AiFillPhone size={50} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
        <h3 className='text-center text-3xl uppercase'>Call</h3>
</a>
    </div>
<div>
<a href='mailto:dbfcutz@gmail.com' target='_blank'>
        <AiIcons.AiOutlineMail size={50} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
        <h3 className='text-center text-3xl uppercase'>Contact</h3>
</a>
    </div>
</div>
</div>
</div>

<Footer />

</>
  )
}

export default Contact