import React from 'react'
import Button from './Button'
import KeyDetails from './KeyDetails'

function BookNowCTA() {
  return (
<div className='bg-red-600 text-white tablet:p-8 flex justify-evenly items-center'>
    <h2 className='tablet:text-4xl'>BOOK NOW ON FRESHA</h2>
    <Button type='external' text='Book Now' link={KeyDetails.bookingLink}/>


</div>
  )
}

export default BookNowCTA