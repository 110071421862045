import React from 'react'
import bg1 from '../assets/images/bg1.png'
import polaroid from '../assets/images/resized/polaroid.jpg'
import trim from '../assets/images/trimadult.png'
import trimkid from '../assets/images/trimkid.png'
import * as AiIcons from 'react-icons/ai'
import Button from './Button'

function Story() {
  return (

    <div className='tablet:flex justify-between py-16 items-center bg-white text-black px-4 tablet:px-32' 
    // style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center', backgroundSize:'cover'}}
    >

<div className='tablet:w-2/3'>
<h2 className='text-2xl tablet:text-5xl'>OUR STORY</h2>
<p className=''>The dynamic duo Obi ‘O1’ and Uche ‘U1’ formally known as Dabeatfreakz were born and raised in Charlton, South East London. Dabeatfreakz are internationally multi-platinum producers, whom have worked with the likes of D block Europe, Krept and Konan, Nines, Cadet, Usher, Sean Paul, Giggs, Deno, Swarmz just to name a few.
</p>
<div className='flex justify-center tablet:justify-start py-4'>
<Button theme='light' text='READ MORE' type='internal' link='/about'/>
</div>

</div>
      <div className='tablet:w-1/3 h-full'>
  <img src={polaroid} className='w-full h-full' alt='polaroid picture of owners'/>
      </div>
</div>


  )
}

export default Story