import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage, storageRef } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyCYfQYLCHxRXQbmZq6Eb_pHw4JHBWRD2ys",
    authDomain: "dbfcutztgf.firebaseapp.com",
    projectId: "dbfcutztgf",
    storageBucket: "dbfcutztgf.appspot.com",
    messagingSenderId: "410487251099",
    appId: "1:410487251099:web:95c169d98997f73b6ca63e",
    measurementId: "G-X0TD4SFKL9"
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export const storage = getStorage(app)
export const db = getFirestore(app)
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()
