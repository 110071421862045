import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';

function EmailForm() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [emailSent, setEmailSent] = useState(false)


    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    
        emailjs.sendForm('service_rv24eye', 'template_n7oqgj9', e.target, 'jt4VGYhv3BWhXDh3g')
          .then((result) => {
              // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
          }, (error) => {
              console.log(error.text);
          });
          setEmailSent(true)
      }


  return (
      <>

      <div className='flex justify-center'>

      {!emailSent ? 

  <form onSubmit={sendEmail} className='flex flex-col'>
      {/* register your input into the hook by invoking the "register" function */}
      <input type="text" placeholder='Name' {...register("name", { required: true })} className='text-black p-1 m-1 w-full border rounded'/>

      <input type="email"  placeholder='Email' {...register("email", { required: true })} className='text-black p-1 m-1 w-full border rounded'/>

      <textarea type="text" placeholder='Message' {...register("message", { required: true })} className='text-black p-1 m-1 w-full border rounded'/>


      

      {errors.exampleRequired && <span>This field is required</span>}
      
      <input type="submit" className='border border-black px-4 py-2 m-2 uppercase hover:bg-black hover:text-white text-center w-full'/>
    </form>

    :

    <p>Thanks, your message was sent.</p>}
      </div>
      </>
  )
}

export default EmailForm