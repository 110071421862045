import React from 'react'

const OpenHours = [
    {
    day: 'Monday',
    hours: '10 a.m. - 9 p.m.'
},
{
    day: 'Tuesday',
    hours: '10 a.m. - 9 p.m.'
},
{
    day: 'Wednesday',
    hours: '10 a.m. - 9 p.m.'
},
    {
    day: 'Thursday',
    hours: '10 a.m. - 9 p.m.'
},

{
    day: 'Friday',
    hours: '10 a.m. - 9 p.m.'
},

{
    day: 'Saturday',
    hours: '10 a.m. - 9 p.m.'
},

{
    day: 'Sunday',
    hours: '11 a.m. - 7 p.m.'
},

]

export default OpenHours