import React, { useEffect, useState } from 'react'
import hero1 from '../assets/images/Hero/resized/hero1.jpg'
import hero2 from '../assets/images/Hero/resized/hero2.jpg'
import hero3 from '../assets/images/Hero/resized/hero3.jpg'
import hero4 from '../assets/images/Hero/resized/hero4.jpg'
import hero5 from '../assets/images/Hero/resized/hero5.jpg'
import hero6 from '../assets/images/Hero/resized/hero6.jpg'
import hero7 from '../assets/images/Hero/resized/hero7.jpg'
import giggs from '../assets/images/Hero/giggs.png'
import hero8 from '../assets/images/Hero/resized/hero8.jpg'
import hero9 from '../assets/images/Hero/resized/hero9.jpg'
import hero10 from '../assets/images/Hero/resized/hero10.jpg'
import hero11 from '../assets/images/Hero/resized/hero11.jpg'
import hero12 from '../assets/images/Hero/resized/hero12.jpg'
import hero13 from '../assets/images/Hero/resized/hero13.jpg'

import hero17 from '../assets/images/Hero/resized/hero17.jpg'
import hero18 from '../assets/images/Hero/resized/hero18.jpg'
import hero19 from '../assets/images/Hero/resized/hero19.jpg'
import hero20 from '../assets/images/Hero/resized/hero20.jpg'
import hero14 from '../assets/images/Hero/hero14sm.jpg'
import hero15 from '../assets/images/Hero/hero15.jpg'
import logo from '../assets/images/logo.png'
import heads from '../assets/images/headsContained.png'
import logotype from '../assets/images/typeContained.png'
import * as BsIcons from 'react-icons/bs'
import '../components/KenBurns/styles.scss'
import Button from './Button'
import useWindowDimensions from '../tools/WindowDimensions'
import { useWindowScrollPositions } from '../tools/ScrollPosition'
import * as AiIcons from 'react-icons/ai'
import * as FaIcons from 'react-icons/fa'
import NavData from './NavData'
import { Link } from 'react-router-dom'
import KeyDetails from './KeyDetails'
import bYoung from '../assets/images/bYoung.jpg'

function Hero() {

    const slides = [hero1, bYoung, hero17, hero18, hero19, hero20, hero14, hero15, hero2, hero3, hero4, hero5, hero6, hero8, hero9, hero10, hero11, hero12, hero13]
    const [slideNumber, setSlideNumber] = useState(0)
    const bgImage = slides[slideNumber]
    const [imageTransition, setImageTransition] = useState(true)


    useEffect(() => {
        setTimeout(() => {
          slideNumber === slides.length -1 ?
            setSlideNumber(0)
          :
          setSlideNumber(slideNumber + 1)
          setImageTransition(!imageTransition)
  
        }, 5000)
      })




      const {width} = useWindowDimensions()
      const [nav, setNav] = useState(false)
      const [fade, setFade] = useState(false)
      const [top, setTop] = useState(true)
      const {scrollY}  = useWindowScrollPositions()
  
  useEffect(() => {
   
          setTimeout(() => {
              setFade(true)
      }, [200])
      
  })
  
  useEffect(() => {
      if (scrollY > 10) {
          setTop(false)
      } else if (scrollY <11) {
          setTop(true)
      }
  })

      


  return (

    <>



  {nav ? 

<div className='h-screen bg-black/90 fixed p-4 tablet:p-16 w-screen z-20'>
    <div className={fade ? 'opacity-1 transition-opacity duration-300 m-0 h-full border-0' : 'opacity-0'}>
     <div className='flex justify-end hover:text-red-600 text-white' onClick={() => setNav(false)}>

     <AiIcons.AiOutlineCloseCircle size={40}/>
</div>
     <div className='m-auto text-2xl tablet:text-4xl text-center py-8 tablet:py-32 pb-16'>
     {NavData.map(nav => (
         <Link to={nav.path} key={nav.title}>
    <div className='text-white uppercase p-4' onClick={() => setNav(false)}>
     <h2 className='hover:text-red-600 hover:scale-125 duration-300'>{nav.title}</h2>
     </div>
     </Link>
))}

     </div>


<div className='flex justify-center pt-8 text-white'>

<a href='http://instagram.com/dbfcutz' target='_blank' onClick={() => setNav(false)}>
 <AiIcons.AiFillInstagram size={40} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
 </a>
<a href='https://www.facebook.com/dbf.cutz' target='_blank' onClick={() => setNav(false)}>
 <FaIcons.FaFacebook size={35} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
 </a>
<a href='https://twitter.com/dbfcutz' target='_blank' onClick={() => setNav(false)}>
 <FaIcons.FaTwitter size={35} className='mx-8 hover:text-red-600 hover:scale-125 duration-300'/>
 </a>
</div>
   
     </div>
</div>

: null}

    {top ? 
<div className='fixed top-0 w-screen p-16 pt-16 z-10'>
<div className='flex justify-end text-white'>
    <AiIcons.AiOutlineMenu size={40} onClick={() => setNav(true)}/>
</div>
</div>
: null }


    <div className="row">
  <div className="kenburns-wrap ">
    <img src={bgImage} className="kenburns object-cover" alt='hero image' />
  </div>
  <div className='content' style={{width:'100vw'}}>

  <div className='bg-black/40 h-screen flex flex-col'>

<div className='m-auto'>



    <div className=' w-screen flex justify-center pb-44'>
    <img src={heads} alt='logo image' className={!fade ? 'pl-8 w-96 tablet:w-max absolute translate-y-16 opacity-0 transition ease-in duration-1000 delay-500' : 'pl-8 w-96 tablet:w-max absolute translate-y-0 opacity-1 transition ease-in duration-1000 delay-500'}/>
    <img src={logotype} alt='logo text' className={!fade ? 'opacity-0 pl-8 w-96 tablet:w-max absolute transition-opacity duration-1000 ' : ' transition-opacity duration-1000 opacity-1 pl-8 w-96 tablet:w-max absolute'}/>
    </div>

    <div className='flex justify-center text-white pt-8 z-20'>
  <Button text='BOOK NOW' link={KeyDetails.bookingLink} type='external'/>
    </div>
</div>
<div className='text-red-600 text-center pb-16 flex justify-center z-0'>
<BsIcons.BsChevronDoubleDown size={45} className='animate-bounce'/>

</div>
</div>


  </div>
</div>


    </>
  )
}

export default Hero