import React from 'react'
import './App.css'
import About from './screens/About'
import Haircuts from './screens/Haircuts'
import Prices from './screens/Prices'
import Team from './screens/Team'
import Contact from './screens/Contact'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import NavBar from './components/NavBar';
import Home from './screens/Home';
import ScrollToTop from './tools/ScrollToTop'
import Products from './screens/Products'
import NotFound from './screens/NotFound'


function App() {
  return (
    <>



     <BrowserRouter>
    <NavBar />
    <ScrollToTop>

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/prices" element={<Prices />} />
      <Route path="/haircuts" element={<Haircuts />} />
      <Route path="/products" element={<Products />} />
      <Route path="/team" element={<Team />} />
      <Route path="/contact" element={<Contact />} />
      <Route path='*' element={<NotFound />} />

    </Routes>
    </ScrollToTop>
  </BrowserRouter>

  

      </>
  )
}

export default App